.vuiFlexItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 0%;
}

.vuiFlexItem--truncate {
  min-width: 40px;
}

// alignItems
$alignItems: (
  alignItemsBaseline: baseline,
  alignItemsCenter: center,
  alignItemsEnd: end,
  alignItemsStart: start,
  alignItemsStretch: stretch
);

@each $alignItemsName, $alignItemsValue in $alignItems {
  .vuiFlexItem--#{$alignItemsName} {
    align-items: $alignItemsValue;
  }
}

@for $i from 0 through 10 {
  .vuiFlexItem--flexGrow#{$i} {
    flex-grow: $i;
  }
}

.vuiFlexItem--flexGrowNone {
  flex-basis: auto;
  flex-grow: 0;
}

@for $i from 0 through 10 {
  .vuiFlexItem--flexShrink#{$i} {
    flex-shrink: $i;
  }
}

.vuiFlexItem--flexShrinkNone {
  flex-basis: auto;
  flex-shrink: 0;
}
