@import "chatTurn";

$minChatHeight: 600px;
$minChatWidth: 600px;

.vuiChatButton,
.vuiChat {
  position: fixed;
  right: $sizeXxs;
  bottom: $sizeXxs;
  z-index: $chatZIndex;
}

.vuiChatButton-isHidden,
.vuiChat--closed {
  // If we used display: none, then the button's animation would play every
  // time the button is shown.
  visibility: hidden;
  // For some reason the buttons inside the header are visible for an extra frame
  // after closing the chat. This fixes that flicker.
  opacity: 0;
}

.vuiChatButton {
  padding: $sizeXs $sizeS;
  font-size: $fontSizeStandard;
  color: $colorText;
  background-color: $colorPrimaryLightShade;
  border: 1px solid $borderColor;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all $transitionSpeed;
  animation: popUp 0.4s cubic-bezier(0.5, 0, 0.5, 1) 1;

  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    translate: translateY(-20px);
  }
}

@keyframes popUp {
  0% {
    transform: translateY(40px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.vuiChat {
  display: flex;
  flex-direction: column;
  max-width: 420px;
  border-radius: $sizeXs;
  overflow: hidden;
  border: 1px solid $borderColor;
  background-color: $colorLightShade;

  @media screen and (max-height: $minChatHeight) {
    & {
      bottom: $sizeXxs;
      height: calc(100vh - 2 * #{$sizeXxs});
    }

    .vuiChat__conversation {
      max-height: 100%;
    }
  }

  @media screen and (max-width: $minChatWidth) {
    & {
      right: $sizeXxs;
      width: calc(100vw - 2 * #{$sizeXxs});
      max-width: 100% !important;
    }
  }
}

.vuiChat--tall {
  bottom: $sizeXxs;
  height: calc(100vh - 2 * #{$sizeXxs});

  .vuiChat__conversation {
    max-height: 100%;
  }
}

.vuiChat--fullScreen {
  height: calc(100vh - 2 * #{$sizeXxs});
  width: calc(100vw - 2 * #{$sizeXxs});
  max-width: 100% !important;

  .vuiChat__conversation {
    max-height: 100%;
  }
}

.vuiChat__header {
  padding: $sizeXs $sizeS;
  font-size: $fontSizeStandard;
  color: $colorText;
  background-color: $colorPrimaryLightShade;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  // Ensure shadow overlaps on top of conversation.
  z-index: 2;
}

.vuiChat__conversation {
  flex-grow: 1;
  max-height: 400px;
  overflow-y: auto;
}

.vuiChat__introduction {
  padding: $sizeM $sizeL 0;
  font-size: $fontSizeStandard;
  color: $colorFullShade;
}

.vuiChat__turns {
  font-size: $fontSizeStandard;
}

.vuiChat__conversationActions {
  padding: $sizeS;
}

.vuiChat__input {
  border-top: 1px solid $borderColorLight;
  padding: $sizeXs $sizeS;
}

.vuiChatPanel {
  position: absolute;
  z-index: 5;
  top: $sizeXxs;
  left: $sizeXxs;
  right: $sizeXxs;
  bottom: $sizeXxs;
  padding: $sizeXxs $sizeS;
  overflow-y: auto;
  background-color: $colorEmptyShade;
  border: 1px solid $borderColor;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
