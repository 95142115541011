.vuiSummary {
  font-size: $fontSizeMedium;
  white-space: pre-line;
}

.vuiSummaryCitation {
  position: relative;
  top: -2px;
  display: inline-block !important;
}

.vuiSummaryCitation-isSelected {
  background-color: $colorPrimary;
  color: $colorEmptyShade;
}
