$size: 16px !default;

$sizeXxxs: 2px !default;
$sizeXxs: 4px !default;
$sizeXs: 8px !default;
$sizeS: 12px !default;
$sizeM: $size !default;
$sizeL: 24px !default;
$sizeXl: 32px !default;
$sizeXxl: 40px !default;
